<template>
  <v-toolbar>
        <v-toolbar-title class="text-grey" style="text-align: left;">
          CheckIns
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
  <div  class="App-Conteudo" id="App-Conteudo" style="margin-top: 0px !important">
    <div class="App-Conteudo-Comp">
      <div class="App-Conteudo-Comp-Box">
<v-snackbar
      v-model="snackbar"
      multi-line
    >
      {{ text }}

      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
        <v-dialog
          v-model="dialogAddCheck"
          width="auto"
        >
          <v-card>
            <v-card-text>
              <v-chip v-for="item in aulasDoDia" :key="item" style="cursor: pointer; margin: auto"  @click="adicionarCheck(item)">
                {{item.nome}}: {{item.horario}} <br/>
              </v-chip>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="dialogAddCheck = false"
                >
                  Cancelar
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <spacer/>
        <h5>Selecione o aluno:</h5>
        <v-autocomplete
          v-model="selectAluno"
          :items="alunos"
          item-title="nome"
          item-value="id"
          label="Selecione o aluno"
          persistent-hint
          return-object
          single-line
        ></v-autocomplete>

        <br/><br/>
        <h5 v-if="selectAluno != null">Clique no dia e selecione a aula para adicionar o checkIN correspondente</h5><br/>

        <VCalendar expanded :columns="3"
          v-if="selectAluno != null"
          :initial-page="{ month: 1, year: new Date().getFullYear() }"
          :attributes="attributes"
           @dayclick="clicouData"
        />
        <VCalendar expanded :columns="3"
          v-if="selectAluno != null"
          :initial-page="{ month: 4, year: new Date().getFullYear() }"
          :attributes="attributes"
          @dayclick="clicouData"
        />
        <VCalendar expanded :columns="3"
          v-if="selectAluno != null"
          :initial-page="{ month: 7, year: new Date().getFullYear() }"
          :attributes="attributes"
          @dayclick="clicouData"
        />
        <VCalendar expanded :columns="3"
          v-if="selectAluno != null"
          :initial-page="{ month: 10, year: new Date().getFullYear() }"
          :attributes="attributes"
          @dayclick="clicouData"
        />

         <v-btn class="font-weight-bold atlas-cta-wide cta-green my-3" color="primary" v-if="checks.length > 0" style="width: 100%; margin-top: 50px !important" v-on:click="exportarcsv()">EXPORTAR csv</v-btn>
         <v-table style="margin-bottom: 100px">
          <thead>
            <tr>
              <th class="text-left">
                
              </th>
              <th class="text-left">
                Matricula
              </th>
              <th class="text-left">
                Nome
              </th>
              <th class="text-left">
                Aula
              </th>
              <th class="text-left">
                Dia
              </th>
              <th class="text-left">
                Horario
              </th>
              <th class="text-left">
                Data do Check
              </th>
            </tr>
          </thead>
          <tbody>
            <tr  v-for="item in checks" :key="item.id">
              <td><div :style="{'background-image':'url('+item.foto+')'}" class="divAvatar"></div></td>
              <td>{{item.matricula}}</td>
              <td>{{item.nomeAluno}}</td>
              <td>{{item.nomeMateria}}</td>
              <td>{{item.diaSemana}}</td>
              <td>{{item.horario}}</td>
              <td>{{formatarData(item.dataCheck, true)}}</td>
            </tr>
          </tbody>
        </v-table>

      </div>


       

    </div>
  </div>
</template>
  
<script>


import {func} from '../assets/functions.js';
  export default {
    name: 'CheckInsConf',
    components:{ 
      
    },
    props: {
      
    },
    async created(){

    },
    data() {
      return {
        snackbar: false,
        text: null,
        materiasAcademia: [],
        checksFull: [],
        checks: [],
        attributes: [],
        alunos: [],
        selectAluno: null,
        materia: null,
        dialogAddCheck: false,
        dialogNaoEncontrado: false,
        aulasDoDia: [],
        diaClicado: null,
      }
    },
    mounted: function () {
        this.carregarTela()
    },
    watch: {
        
        selectAluno: function(value) {
            this.selecionarChecksAluno();
        }
    },
    methods: {
      exportarcsv(){
        const rows = [];
        var cabecalho = [
          "Nome",
          "Matricula",
          "Data",
          "Dia da Semana",
          "Modalidade",
        ];
        rows.push(cabecalho)
        for(var i = 0; i < this.checks.length; i++){
          var objeto = [
            this.checks[i].nomeAluno,
            this.checks[i].matricula,
            this.formatarData(this.checks[i].dataCheck, true),
            this.checks[i].diaSemana,
            this.checks[i].nomeMateria,
          ]
          rows.push(objeto)
        }

        let csvContent = "data:text/csv;charset=utf-8,"  + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
      },
      clicouData(event){
        this.diaClicado = event.id;
        var post = {
          dia: event.id
        }
        func.CarregarDados('checkins/selecionarAulasDoDia', post).then(data => 
          this.abriraddCheck(data.corpo)
        );
      },
      abriraddCheck(data){
        var novoArrayMaterias = [];
        for(var item1 in this.selectAluno.materias) {
          var existe = false;
          for(var item2 in data) {
            if(this.selectAluno.materias[item1].id == data[item2].idMateria){
              novoArrayMaterias.push(data[item2])
            }
          }
        }

        if(novoArrayMaterias.length > 0){
          this.aulasDoDia = novoArrayMaterias
          this.dialogAddCheck = true
        }
        else{
          this.snackbar = true;
          this.text = "Nenhuma aula encontrada para este dia"
        }
      },
      selecionarChecksAluno(aluno){
        var post = {
          idAluno: this.selectAluno.id 
        }
        func.CarregarDados('checkins/getCheckInsPorAluno', post).then(data => 
          this.carregarComponentes(data)
        );
      },

      filtrarMateria(materia){
        if(materia > 0){
          this.checks = this.checksFull.filter(x => x.idMateria == materia);
        }
        else{
          this.checks = this.checksFull
        }
        this.mostrarComponentes(this.materiasAcademia)
      },

      carregarTela(){
        func.CarregarDados('alunos/getAlunosChecks').then(data => 
          this.alunos = data.corpo
        );
        
      },

      adicionarCheck(dado){
        var post = {
          idAluno: this.selectAluno.id,
          idAula: dado.id,
          dataAula: this.diaClicado
        }
       
        func.CarregarDados('checkins/addCheckInProfessor', post).then(data => 
          this.carregarCalendarios()
        );
      },
      carregarCalendarios(){
        this.text = "CheckIN adicionado com sucesso"
        this.snackbar = true;
        this.dialogAddCheck =  false;
        this.selecionarChecksAluno(null)
      },
      formatarData(data, comHora){
        return func.formatarDataPadraoBR(data, comHora);
      },
      congruente(dados){
        this.materiasAcademia = dados;
      },
      carregarComponentes(dados){
        this.checksFull = dados.corpo;
        this.checks = dados.corpo;
        var novasdatas = []; 
        func.CarregarDados('professor/getMateriasProfessor').then(data => 
          this.mostrarComponentes(data.corpo)
        );
      },

      mostrarComponentes(materiasAcademia){
        this.materiasAcademia = materiasAcademia
        //agrupar datas
        var datas = [];
        for(var key in this.checks) {
          var dataFormatada = func.formatarDataPadraoBR(this.checks[key].dataCheck, false);
          if(datas.filter(x => x == dataFormatada).length == 0 ){
            datas.push(dataFormatada)
          }
        }

        var dadosFinais = [];
        
        for(var data in datas) {
          var qtdTotalPorData = this.checks.filter(x => func.formatarDataPadraoBR(x.dataCheck, false) == datas[data]).length;

          for(var materia in materiasAcademia) {
            var dataMateria = datas[data];
            var idMateria = materiasAcademia[materia].id;
            var nomeMateria = materiasAcademia[materia].nome;
            var qtdPorMateriaNaData = this.checks.filter(x => x.idMateria == idMateria && func.formatarDataPadraoBR(x.dataCheck, false) == datas[data]).length

            var ano = dataMateria.substring(10, 6)
            var mes = dataMateria.substring(3, 5)
            var dia = dataMateria.substring(0, 2)
            
            var dadoInserir = {
              dates: new Date(ano, mes - 1, dia),
              highlight: true,
              popover: {
                label: nomeMateria + " - " + qtdPorMateriaNaData,
              },
            }
            dadosFinais.push(dadoInserir)
          }
        }

        this.attributes = dadosFinais;
        


      }
    }
  }

</script>
  

  